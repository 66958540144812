import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Meta from "../../components/content/Meta";
import PasswordProtectForm from "../../components/content/PasswordProtectForm";
import contentfulComponentMapREST from "../../utils/contentfulComponentMapREST";
import { SettingsContext } from "../../context/SettingsContext";
import S from "./styles";
import { ContentfulPasswordProtectedPage } from "../../types";

export const query = graphql`
  query PasswordProtectedPageQuery($contentful_id: String!) {
    pageData: contentfulPasswordProtectedPage(
      contentful_id: { eq: $contentful_id }
    ) {
      contentful_id
      title
      metadata {
        ...Metadata
      }
    }
  }
`;

interface PasswordProtectedPageProps {
  data?: {
    pageData: ContentfulPasswordProtectedPage;
  };
  pathContext: any;
}

const PasswordProtectedPage = ({
  data,
  pathContext,
}: PasswordProtectedPageProps) => {
  const [protectedComponents, setProtectedComponents] = useState(null);
  const { setSettings } = useContext(SettingsContext);

  if (!data || !data.pageData) return null;

  const {
    contentful_id,
    metadata = {},
    passwordRevalidationDays,
  } = data.pageData;

  useEffect(() => {
    if (pathContext.settings) {
      setSettings(pathContext.settings);
    }
  }, []);

  return (
    <Layout pathContext={pathContext}>
      <Meta
        defaults={pathContext.settings.defaultMetadata}
        meta={{ ...metadata }}
      />
      <S.Content>
        {protectedComponents ? (
          protectedComponents.map((component, index) => {
            const { fields, sys } = component;

            const Component =
              contentfulComponentMapREST[sys?.contentType?.sys?.id];

            if (!Component) return null;

            return (
              <Component key={index} {...fields} pathContext={pathContext} />
            );
          })
        ) : (
          <PasswordProtectForm
            setProtectedComponents={setProtectedComponents}
            pageId={contentful_id}
            passwordProtectEndpoint={
              pathContext?.settings?.passwordProtectEndpoint
            }
            passwordRevalidationDays={passwordRevalidationDays}
          />
        )}
      </S.Content>
    </Layout>
  );
};

export default PasswordProtectedPage;
