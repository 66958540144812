import React, { useContext } from "react";
import slugify from "slugify";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  contentfulRichTextLinks,
  contentfulRichTextInlineImage,
  contentfulRichTextBlockquoteWithQuoteSVG,
  contentfulAssetLinks,
} from "../../../utils/helpers";
import DocumentIcon from "../../common/DocumentIcon";
import { Document } from "@contentful/rich-text-types";
import { ContentfulImageREST } from "../../../types";
import S from "./styles";
import { SettingsContext } from "../../../context/SettingsContext";

interface FactSheetsSectionProps {
  background: ContentfulImageREST;
  heading: string;
  content: Document;
  sheets: Document;
}

const FactSheetsSection = ({
  background,
  heading,
  content,
  sheets,
}: FactSheetsSectionProps) => {
  const { settings } = useContext(SettingsContext);

  return (
    <S.Section id={slugify(heading)}>
      <S.SectionBackgroundImage
        overlayOpacityColor="#fff"
        overlayOpacityLevel={0.9}
      >
        <img src={background?.fields?.file?.url} />
      </S.SectionBackgroundImage>
      <S.FactSheetsContent>
        <S.InfoPanel>
          <h3>{heading}</h3>
          <S.IconDivider>
            <span />
            <DocumentIcon />
            <span />
          </S.IconDivider>
          {documentToReactComponents(content, {
            renderNode: {
              ...contentfulRichTextLinks({ pageMap: settings.pageMap }),
              ...contentfulRichTextInlineImage,
              ...contentfulRichTextBlockquoteWithQuoteSVG({
                colourScheme: "light",
              }),
            },
          })}
        </S.InfoPanel>
        <S.ContentPanel>
          {documentToReactComponents(sheets, {
            renderNode: {
              ...contentfulRichTextLinks({ pageMap: settings.pageMap }),
              ...contentfulRichTextInlineImage,
              ...contentfulAssetLinks(<DocumentIcon />),
              ...contentfulRichTextBlockquoteWithQuoteSVG({
                colourScheme: "light",
              }),
            },
          })}
        </S.ContentPanel>
      </S.FactSheetsContent>
    </S.Section>
  );
};

export default FactSheetsSection;
