import styled from "styled-components";
import { theme } from "../../../styles/theme";

const SystemSafety = styled.div`
  position: relative;
`;

const Section = styled.section`
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SectionBackgroundImage = styled.div<{
  overlayOpacityColor: string;
  overlayOpacityLevel: number;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;

  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-color: ${({ overlayOpacityColor }) => overlayOpacityColor};
    opacity: ${({ overlayOpacityLevel }) => overlayOpacityLevel};
    z-index: 2;
    top: 0;
    left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const NavContent = styled.div`
  position: relative;
  padding: 70px 50px;
  max-width: 1240px;
  width: 100%;
  margin: 120px 20px;
  z-index: 10;
  color: #fff;
  border: 1px solid #555;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.85);

  h1 {
    text-transform: uppercase;
    margin: 0 0 20px 0;
    font-size: 22px;
    letter-spacing: 3px;
  }

  h2 {
    text-transform: uppercase;
    margin: 0 0 40px 0;
    font-size: 35px;
    letter-spacing: 5px;
  }

  .content {
    font-family: ${({ theme }) => theme.fonts.helveticaRegular};
    line-height: 1.5;
  }

  .buttons {
    position: relative;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 700px) {
      flex-direction: column;

      a {
        margin-bottom: 20px;
      }
    }

    a {
      border-radius: 5px;
      display: inline-block;
      border: 1px solid #777;
      text-decoration: none;
      text-transform: uppercase;
      color: #fff;
      background-color: #404040;
      padding: 15px 30px;
      transition: all 0.2s ease;

      &:hover {
        background-color: rgba(5, 206, 124, 1);
        color: #404040;
        border-color: rgba(5, 206, 124, 1);
      }
    }
  }
`;

const InfoPanel = styled.div`
  position: relative;
  text-align: center;
  width: 50%;
  flex: 1;
  padding: 0 50px;
  font-family: ${({ theme }) => theme.fonts.helveticaRegular};
  line-height: 1.5;

  @media screen and (max-width: 900px) {
    width: 100%;
    order: 1;
    margin-bottom: 50px;
  }

  h3 {
    text-transform: uppercase;
    font-size: 35px;
    letter-spacing: 5px;
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

const ContentPanel = styled.div`
  position: relative;
  text-align: center;
  width: 50%;
  flex: 1;
  padding: 0 50px;

  @media screen and (max-width: 900px) {
    width: 100%;
    order: 2;
  }
`;

const FactSheetsContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 50px 0;
  }

  ul {
    text-align: left;
    list-style: none;
    padding: 0;

    a {
      text-decoration: none;
      transition: color 0.2s ease;

      &:hover {
        color: rgba(5, 206, 124, 1);
      }
    }

    svg {
      margin-right: 5px;
      width: 20px;
    }
  }
`;

const AskQuestionContent = styled.div`
  position: relative;
  display: flex;
  color: #fff;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  @media screen and (max-width: 900px) {
    padding: 50px 0;
    flex-direction: column;
  }
`;

const FeedbackContent = styled.div`
  position: relative;
  color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  ul {
    padding: 0;
    list-style: none;

    li {
      a {
        display: inline-block;
        text-decoration: none;
        padding: 15px 40px;
        border-radius: 5px;
        text-transform: uppercase;
        letter-spacing: 3px;

        @media screen and (max-width: 900px) {
          display: block;
        }
      }

      &:nth-of-type(3n + 1) {
        a {
          background-color: #fcca44;
        }
      }

      &:nth-of-type(3n + 2) {
        a {
          background-color: #215682;
          color: #fff;
        }
      }
      &:nth-of-type(3n + 3) {
        a {
          background-color: #bf3945;
          color: #fff;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 50px 0;
  }
`;

const IconDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;

  span {
    position: relative;
    display: block;
    flex: 1;
    height: 3px;
    background-color: #666;
  }

  svg {
    background-color: #5c5c5c;
    padding: 10px;
    width: 60px;
    border-radius: 50%;
    stroke: #e5e5e5;
    margin: 0 20px;
  }
`;

const AskQuestionForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;

  div {
    margin-bottom: 20px;
  }

  label,
  input,
  textarea {
    display: block;
    width: 100%;
  }

  input,
  textarea {
    background-color: #eee;
  }

  input {
    padding: 10px 20px;
  }

  textarea {
    padding: 20px;
  }

  label {
    text-align: left;
    margin-bottom: 5px;
  }

  button {
    position: relative;
    padding: 20px;
    border: 2px solid #777;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #404040;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #777;
    }

    span {
      display: flex;
      align-items: center;

      svg {
        display: block;
        width: 30px;
        height: auto;
        padding-right: 10px;
      }
    }
  }
`;

const Message = styled.div<{ type: "error" | "success" }>`
  position: relative;
  margin-top: 10px;
  padding: 10px;
  background-color: ${({ type }) =>
    type === "error" ? "rgba(255, 0,0,0.2)" : "rgba(0,255,0,0,0.2)"};
`;

export default {
  SystemSafety,
  Section,
  SectionBackgroundImage,
  NavContent,
  FactSheetsContent,
  AskQuestionContent,
  AskQuestionForm,
  FeedbackContent,
  InfoPanel,
  ContentPanel,
  IconDivider,
  Message,
};
