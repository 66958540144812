import React, { useEffect, useContext } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import slugify from "slugify";
import { HeaderContext } from "../../../context/HeaderContext";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";
import {
  contentfulRichTextLinks,
  contentfulRichTextInlineImage,
  contentfulRichTextBlockquoteWithQuoteSVG,
} from "../../../utils/helpers";
import { ContentfulImageREST } from "../../../types";
import S from "./styles";
import { SettingsContext } from "../../../context/SettingsContext";

interface NavSectionProps {
  background: ContentfulImageREST;
  heading: string;
  subheading?: string;
  content: Document;
  factSheetsHeading: string;
  askQuestionHeading: string;
  feedbackHeading: string;
}

const NavSection = ({
  background,
  heading,
  subheading,
  content,
  factSheetsHeading,
  askQuestionHeading,
  feedbackHeading,
}: NavSectionProps) => {
  const { setHeaderColor, setInitialHeaderColor } = useContext(HeaderContext);
  const { settings } = useContext(SettingsContext);

  useEffect(() => {
    setHeaderColor("dark");
    setInitialHeaderColor("dark");
  }, []);

  return (
    <S.Section>
      <S.SectionBackgroundImage
        overlayOpacityLevel={0.2}
        overlayOpacityColor="#000"
      >
        <img src={background?.fields?.file?.url} />
      </S.SectionBackgroundImage>
      <S.NavContent>
        <h1>{heading}</h1>
        {subheading && <h2>{subheading}</h2>}
        <div className="content">
          {documentToReactComponents(content, {
            renderNode: {
              ...contentfulRichTextLinks({ pageMap: settings.pageMap }),
              ...contentfulRichTextInlineImage,
              ...contentfulRichTextBlockquoteWithQuoteSVG({
                colourScheme: "light",
              }),
            },
          })}
        </div>
        <div className="buttons">
          <AnchorLink href={`#${slugify(factSheetsHeading)}`}>
            {factSheetsHeading}
          </AnchorLink>
          <AnchorLink href={`#${slugify(askQuestionHeading)}`}>
            {askQuestionHeading}
          </AnchorLink>
          <AnchorLink href={`#${slugify(feedbackHeading)}`}>
            {feedbackHeading}
          </AnchorLink>
        </div>
      </S.NavContent>
    </S.Section>
  );
};

export default NavSection;
