import React from "react";
import NavSection from "./NavSection";
import FactSheetsSection from "./FactSheetsSection";
import AskQuestionSection from "./AskQuestionSection";
import FeedbackSection from "./FeedbackSection";
import S from "./styles";
import { ContentfulSystemSafetyREST } from "../../../types";

export interface SystemSafetyProps extends ContentfulSystemSafetyREST {
  [prop: string]: any;
}

const SystemSafety = ({
  navSectionBackgroundImage,
  navSectionHeading,
  navSectionSubheading,
  navSectionContent,
  factSheetsBackgroundImage,
  factSheetsHeading,
  factSheetsContent,
  factSheetsList,
  askQuestionBackgroundImage,
  askQuestionHeading,
  askQuestionContent,
  askQuestionFormEndpoint,
  feedbackBackgroundImage,
  feedbackHeading,
  feedbackContent,
  feedbackButtonLinks,
  ...rest
}: SystemSafetyProps) => {
  return (
    <S.SystemSafety {...rest}>
      <NavSection
        background={navSectionBackgroundImage}
        heading={navSectionHeading}
        subheading={navSectionSubheading}
        content={navSectionContent}
        askQuestionHeading={askQuestionHeading}
        feedbackHeading={feedbackHeading}
        factSheetsHeading={factSheetsHeading}
      />
      <FactSheetsSection
        background={factSheetsBackgroundImage}
        heading={factSheetsHeading}
        content={factSheetsContent}
        sheets={factSheetsList}
      />
      <AskQuestionSection
        background={askQuestionBackgroundImage}
        heading={askQuestionHeading}
        content={askQuestionContent}
        formEndpoint={askQuestionFormEndpoint}
        pageHeading={navSectionHeading}
      />
      <FeedbackSection
        background={feedbackBackgroundImage}
        heading={feedbackHeading}
        content={feedbackContent}
        buttons={feedbackButtonLinks}
      />
    </S.SystemSafety>
  );
};

export default SystemSafety;
