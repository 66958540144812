import React, { useContext, useEffect, useState } from "react";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import axios from "axios";
import ArrowIcon from "../../common/ArrowIcon";
import S from "./styles";
import SpinnerIcon from "../../common/SpinnerIcon";
import Container from "../../layout/Container";
import { HeaderContext } from "../../../context/HeaderContext";
import { SettingsContext } from "../../../context/SettingsContext";

export interface PasswordProtectFormProps {
  pageId: string;
  passwordProtectEndpoint: string;
  passwordRevalidationDays: number;
  setProtectedComponents: React.Dispatch<React.SetStateAction<any>>;
  [prop: string]: any;
}

const PasswordProtectForm = ({
  pageId,
  setProtectedComponents,
  passwordProtectEndpoint,
  passwordRevalidationDays = 7,
  ...rest
}: PasswordProtectFormProps) => {
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const { settings } = useContext(SettingsContext);
  const { setHeaderColor, setInitialHeaderColor } = useContext(HeaderContext);

  useEffect(() => {
    setHeaderColor("dark");
    setInitialHeaderColor("dark");

    // If we have a local storage key matching the page id,
    // parse out the encrypted password and expiry time
    if (window.localStorage.getItem(pageId)) {
      const object = JSON.parse(window.localStorage.getItem(pageId));
      const time = new Date().getTime() / 1000;

      // If not expired, set the password automatically to fetch the data
      if (object.e > time) {
        const lsPassword = CryptoAES.decrypt(
          object.p,
          process.env.GATSBY_PASSWORD_ENCRYPTION_KEY
        ).toString(CryptoENC);

        if (lsPassword) {
          setPassword(lsPassword);
          fetchData(lsPassword);
        }
      } else {
        // Time has expired so delete key
        localStorage.removeItem(pageId);
      }
    }
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();

    if (password) {
      fetchData(password);
    }
  };

  const fetchData = async pw => {
    setMessage("");
    setSubmitting(true);

    try {
      const { data } = await axios.post(
        passwordProtectEndpoint,
        {
          pageId: pageId,
          password: pw,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // If data is returned, the password must be valid so
      // encrypt and store in local storage for the amount of days
      // specified so that user doesn't have to re-enter password on
      // page reload
      if (data) {
        const time = new Date().getTime() / 1000;
        const weekFuture = Math.round(
          time + 3600 * 24 * passwordRevalidationDays
        );
        const encrypted = CryptoAES.encrypt(
          pw,
          process.env.GATSBY_PASSWORD_ENCRYPTION_KEY
        ).toString();
        const json = JSON.stringify({
          p: encrypted,
          e: weekFuture,
        });
        window.localStorage.setItem(pageId, json);
        setProtectedComponents(data);
      }
    } catch (error) {
      setMessage("Password is incorrect");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <S.PasswordProtect>
      <Container>
        <S.PasswordForm onSubmit={e => handleSubmit(e)} {...rest}>
          <div className="instructions">
            This content is password protected. To view it please enter your
            password below:
          </div>
          <input
            type="password"
            name="password"
            required
            placeholder="Password*"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />

          <button type="submit" disabled={submitting}>
            {submitting && (
              <span>
                <SpinnerIcon />
              </span>
            )}{" "}
            Submit
          </button>
          {message && <div className="form-message">{message}</div>}
        </S.PasswordForm>
      </Container>
    </S.PasswordProtect>
  );
};

export default PasswordProtectForm;
