import React, { useContext } from "react";
import slugify from "slugify";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";
import {
  contentfulRichTextLinks,
  contentfulRichTextInlineImage,
  contentfulRichTextBlockquoteWithQuoteSVG,
} from "../../../utils/helpers";
import { ContentfulImageREST } from "../../../types";
import S from "./styles";
import { SettingsContext } from "../../../context/SettingsContext";
import PencilIcon from "../../common/PencilIcon";

interface FeedbackSectionProps {
  background: ContentfulImageREST;
  heading: string;
  content: Document;
  buttons: Document;
}

const FeedbackSection = ({
  background,
  heading,
  content,
  buttons,
}: FeedbackSectionProps) => {
  const { settings } = useContext(SettingsContext);

  return (
    <S.Section id={slugify(heading)}>
      <S.SectionBackgroundImage
        overlayOpacityColor="#000"
        overlayOpacityLevel={0.8}
      >
        <img src={background?.fields?.file?.url} />
      </S.SectionBackgroundImage>
      <S.FeedbackContent>
        <S.InfoPanel>
          <h3>{heading}</h3>
          <S.IconDivider>
            <span />
            <PencilIcon />
            <span />
          </S.IconDivider>
          {documentToReactComponents(content, {
            renderNode: {
              ...contentfulRichTextLinks({ pageMap: settings.pageMap }),
              ...contentfulRichTextInlineImage,
              ...contentfulRichTextBlockquoteWithQuoteSVG({
                colourScheme: "light",
              }),
            },
          })}
        </S.InfoPanel>
        <S.ContentPanel>
          {documentToReactComponents(buttons, {
            renderNode: {
              ...contentfulRichTextLinks({ pageMap: settings.pageMap }),
              ...contentfulRichTextInlineImage,
              ...contentfulRichTextBlockquoteWithQuoteSVG({
                colourScheme: "light",
              }),
            },
          })}
        </S.ContentPanel>
      </S.FeedbackContent>
    </S.Section>
  );
};

export default FeedbackSection;
