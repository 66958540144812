import React, { useContext, useState } from "react";
import slugify from "slugify";
import axios from "axios";
import { useForm } from "react-hook-form";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";
import {
  contentfulRichTextLinks,
  contentfulRichTextInlineImage,
  contentfulRichTextBlockquoteWithQuoteSVG,
} from "../../../utils/helpers";
import { ContentfulImageREST } from "../../../types";
import S from "./styles";
import { SettingsContext } from "../../../context/SettingsContext";
import SpeechBubbleIcon from "../../common/SpeechBubbleIcon";
import SpinnerIcon from "../../common/SpinnerIcon";

interface AskQuestionSectionProps {
  background: ContentfulImageREST;
  heading: string;
  content: Document;
  formEndpoint: string;
  pageHeading: string;
}

const AskQuestionSection = ({
  background,
  heading,
  content,
  formEndpoint,
  pageHeading,
}: AskQuestionSectionProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState<{
    type: "success" | "error";
    message: string;
  } | null>(null);
  const { settings } = useContext(SettingsContext);
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async data => {
    setSubmitting(true);
    setMessage(null);

    try {
      await axios.post(formEndpoint, {
        name: data.name,
        email: data.email,
        question: data.question,
        submit_page: pageHeading,
      });
      reset();
      setMessage({
        type: "success",
        message: "You have successfully submitted your question",
      });
    } catch (error) {
      setMessage({ type: "error", message: error.message });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <S.Section id={slugify(heading)}>
      <S.SectionBackgroundImage
        overlayOpacityColor="#000"
        overlayOpacityLevel={0.8}
      >
        <img src={background?.fields?.file?.url} />
      </S.SectionBackgroundImage>
      <S.AskQuestionContent>
        <S.ContentPanel>
          <S.AskQuestionForm onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="name">Name*</label>
              <input
                type="text"
                name="name"
                ref={register({ required: true })}
              />
            </div>
            <div>
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                name="email"
                ref={register({ required: true })}
              />
            </div>
            <div>
              <label htmlFor="email">System Safety Question or Query*</label>
              <textarea
                name="question"
                rows={5}
                ref={register({ required: true })}
              ></textarea>
            </div>
            <button disabled={submitting} type="submit">
              <span>
                {submitting && <SpinnerIcon colourScheme="dark" />} Submit to a
                JVAT SME
              </span>
            </button>
            {message && (
              <S.Message type={message.type}>{message.message}</S.Message>
            )}
          </S.AskQuestionForm>
        </S.ContentPanel>
        <S.InfoPanel>
          <h3>{heading}</h3>
          <S.IconDivider>
            <span />
            <SpeechBubbleIcon />
            <span />
          </S.IconDivider>
          {documentToReactComponents(content, {
            renderNode: {
              ...contentfulRichTextLinks({ pageMap: settings.pageMap }),
              ...contentfulRichTextInlineImage,
              ...contentfulRichTextBlockquoteWithQuoteSVG({
                colourScheme: "light",
              }),
            },
          })}
        </S.InfoPanel>
      </S.AskQuestionContent>
    </S.Section>
  );
};

export default AskQuestionSection;
