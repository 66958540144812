import styled from "styled-components";

const PasswordProtect = styled.section`
  position: relative;
  display: flex;
  padding: 200px 0;
  background-color: ${({ theme }) => theme.colors.darkGrey1};
`;

const PasswordForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
  border-radius: 5px;
  padding: 40px;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  color: #333;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

  input,
  textarea {
    background-color: transparent;
    font-family: ${({ theme }) => theme.fonts.poppinsRegular};
    font-size: 20px;
    line-height: 55px;
    background-color: #eee;
    padding: 0 20px;

    &::placeholder {
      color: ${({ theme }) => theme.colors.mediumGrey1};
    }
  }

  input {
    border: none;
    border-bottom: 1px solid #999;
    margin-bottom: 20px;
    border-radius: 0;
    margin-top: 20px;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.colors.darkGrey1};
    padding: 0 20px;
    height: 200px;
  }

  .message {
    display: flex;
    flex-direction: column;
  }

  .instructions {
    margin-bottom: 40px;
  }

  button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.darkGrey1};
    background-color: ${({ theme }) => theme.colors.green1};
    align-self: flex-start;
    display: flex;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
    text-transform: uppercase;
    padding: 18px;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 2.3px;
    cursor: pointer;
    width: 100%;
    justify-content: center;

    span {
      padding-right: 10px;
    }

    .spinner {
      display: none;
    }

    &:disabled {
      .arrow,
      .mobile {
        display: none;
      }

      .spinner {
        display: block;
      }
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      width: 100%;
      display: flex;
      align-items: center;
      border: none;
      color: ${({ theme }) => theme.colors.darkGrey1};
      background-color: ${({ theme }) => theme.colors.green1};

      .arrow {
        display: none;
      }
    }

    svg {
      width: 23px;
      height: 23px;

      &.mobile {
        display: none;
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-left: 0;
        display: none;

        &.mobile {
          display: block;
        }
      }
    }
  }

  .form-message {
    position: absolute;
    color: ${({ theme }) => theme.colors.white1};
    bottom: -60px;
  }
`;

export default {
  PasswordProtect,
  PasswordForm,
};
